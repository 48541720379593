<template functional>
  <svg
    class="icon-breadcrumb"
    :class="props.direction"
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 -6.16331e-08L-2.47757e-07 1.33198L6 7L12 1.33198L10.59 -4.62904e-07L6 4.32659L1.41 -6.16331e-08Z"
      fill="#7F7F7F"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'down'
    }
  }
})
</script>

<style lang="scss" scoped>
.icon-breadcrumb {
  transition: all 0.3s;
  &.up {
    transform:rotate(180deg);
  }
  &.right {
    transform:rotate(-90deg);
  }
  &.left {
    transform:rotate(90deg);
  }
}
</style>
