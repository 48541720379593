import { AxiosResponse } from 'axios';
import { instanceApi } from './instance';

// Get apps list for IntegrationsPage
export const getIntegrationsApps = (poolServiceInstanceId: string): Promise<AxiosResponse> => instanceApi.get(`api/apps?poolServiceInstanceId=${poolServiceInstanceId}`);

// Get apps list for by filter id
/*
  // component category
  AccountsInfo - Banks & Banking data
  PaymentCalendar - Banks & Banking data, Accountancy
  BusinessValuation - Accountancy, eCommerce
  CreditScore - All categories

  // categoryIds
  Banks & Banking - 22afb716-4814-4fad-a685-a43fa2694266
  Accountancy - 2cc07dc1-5f6c-11e9-b797-080027653e25
  eCommerce - 2cc08e47-5f6c-11e9-b797-080027653e25
*/
export const getFilteredAppsList = (payload: any): Promise<AxiosResponse> => instanceApi.post('/api/apps/filter', payload);
