export default {
  namespaced: true,
  state: {
    visitedPagesArray: [],
    nextPage: null,
    previousPage: null,
    pageSettings: {
      RestorePasswordPage: '100',
      BusinessValuePage: '100',
      RegistrationPage: '100',
      NamePage: '100',
      AppsPage: '100',
      BureausPage: '100',
      CreditScorePage: '100',
      LendioPage: '100',
      ThankYouPage: '100',
      PortalPage: '100',
      GetLoanPage: '100',
      IndustryPage: '100',
    },
  },
  getters: {
    isPreviousPageExist(state: any): boolean {
      return state.previousPage !== null;
    },
  },
  mutations: {
    refreshPageSettings(state: any, newPageSettings: any): void {
      state.pageSettings = { ...state.pageSettings, ...newPageSettings };
    },
    setNextPage(state: any, nextPage: any): void {
      state.nextPage = nextPage;
    },
    setPreviousPage(state: any, previousPage: any): void {
      state.previousPage = previousPage;
    },
    addVisitedPagesToArray(state: any, currentPageName: string): void {
      state.visitedPagesArray = [...new Set([...state.visitedPagesArray, currentPageName])];
    },
    resetVisitedPagesArray(state: any): void {
      state.visitedPagesArray = [];
    },
  },
};
