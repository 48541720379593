import { IStage } from '@/models';

export default {
  namespaced: true,
  state: {
    flowList: {
      107: [
        { number: 1, name: 'LandingLoanPage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'AppsPage', isAvailable: false },
        { number: 6, name: 'BureausPage', isAvailable: false },
        { number: 7, name: 'LendioPage', isAvailable: false },
        { number: 8, name: 'ThankYouPage', isAvailable: false },
        { number: 9, name: 'AppStatusesPage', isAvailable: false },
      ],
      108: [
        { number: 1, name: 'AppStatusesPage', isAvailable: true },
        { number: 2, name: 'LendioPage', isAvailable: false },
        { number: 3, name: 'ThankYouPage', isAvailable: false },
        { number: 4, name: 'AppStatusesPage', isAvailable: false },
      ],
      206: [
        { number: 1, name: 'LandingCreditScorePage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'AppsPage', isAvailable: false },
        { number: 6, name: 'CreditScorePage', isAvailable: false },
      ],
      207: [
        { number: 1, name: 'CreditScorePage', isAvailable: false },
      ],
      302: [
        { number: 1, name: 'Landing360Page', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'AppsPage', isAvailable: false },
        { number: 6, name: 'BureausPage', isAvailable: false },
      ],
      508: [
        { number: 1, name: 'LandingValuationPage', isAvailable: true },
        { number: 2, name: 'BusinessValuePage', isAvailable: true },
        { number: 3, name: 'RegistrationPage', isAvailable: false },
        { number: 4, name: 'LoginPage', isAvailable: false },
        { number: 5, name: 'NamePage', isAvailable: false },
        { number: 6, name: 'BusinessValueResultsPage', isAvailable: false },
      ],
      600: [
        { number: 1, name: 'LandingCashManagementPage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'CashManagementPage', isAvailable: false },
      ],
      700: [
        { number: 1, name: 'LandingCarbonPage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'CarbonFootprintPage', isAvailable: false },
      ],
    },
    currentStage: 0,
    currentFlow: '',
    lastRouteName: '',
    completedFlowsArr: [],
  },

  getters: {
    getFlowName(state: any): string {
      const flowDictionary: Record<string, string> = {
        107: 'business-loan',
        108: 'business-loan',
        206: 'credit-score',
        207: 'credit-score',
        302: 'business360',
        508: 'business-valuation',
        600: 'cash-management',
        700: 'carbon-footprint',
      };

      return flowDictionary[state.currentFlow];
    },
    getIsLandingStage(state: any): any {
      return (pageName: string): boolean => pageName === state.flowList[state.currentFlow][0].name;
    },
    getFlowByName(state: any): string {
      return state.flowList[state.currentFlow];
    },
    getCurrentFlow(state: any): string {
      return state.currentFlow;
    },
    getCurrentStage(state: any): number {
      return state.currentStage;
    },
    getNextStage(state: any): string {
      return state.currentFlow === '' ? 'PortalPage' : state.flowList[state.currentFlow].find(((stage: any) => stage.number === state.currentStage + 1)).name;
    },
    getAvailableStage(state: any): any {
      return (pageName: string): boolean => {
        let res;
        if (state.currentFlow !== '' && pageName !== 'PortalPage') {
          res = state.flowList[state.currentFlow].find((stage: IStage) => stage.name === pageName)?.isAvailable;
        } else res = false;

        return res;
      };
    },
    getIsNextPageAvailable(state: any): boolean {
      return state.currentFlow !== '' ? state.flowList[state.currentFlow].find(((stage: any) => stage.number === state.currentStage + 1))?.isAvailable : true;
    },
    getLastRouteName(state: any): string {
      return state.lastRouteName;
    },
    getIsFlowCompleted(state: any): any {
      return (flowVersion: string): boolean => { 
        return state.completedFlowsArr.find((flow: any) => flow.flowVersion === flowVersion)?.status === 'Completed';
      }
    }
  },

  mutations: {
    setCurrentFlowAndStage(state: any, payload: any): void {
      if (payload.flowVersion) {
        state.currentFlow = payload.flowVersion;
      }
      if (payload.pageName && state.currentFlow !== '') state.currentStage = state.flowList[state.currentFlow].find((stage: any) => stage.name === payload.pageName)?.number;

      if (state.flowList && state.currentStage) {
        const index = state.flowList[state.currentFlow].findIndex((obj: any) => obj.number === state.currentStage);

        if (index !== -1) {
          state.flowList[state.currentFlow][index].isAvailable = true;
        }
      }
    },
    setAvailability(state: any, routeName: any): void {
      const availableIndex = state.flowList[state.currentFlow].findIndex((obj: any) => obj.name === routeName);
      if (availableIndex >= 0) {
        state.flowList[state.currentFlow].forEach((obj: any, index: any) => {
          if (index <= availableIndex) {
            state.flowList[state.currentFlow][index].isAvailable = true;
          }
        });
      }
    },
    setCurrentFlowPlusOne(state: any): void {
      state.currentStage += 1;
    },
    setLastRouteName(state: any, routeName: any): void {
      state.lastRouteName = routeName;
    },
    setNextPageAvailable(state: any): void {
      if (state.currentFlow !== '') state.flowList[state.currentFlow].find(((stage: any) => stage.number === state.currentStage + 1)).isAvailable = true;
    },
    setCompletedFlowsArr(state: any, completedFlows: any): void {
      state.completedFlowsArr = completedFlows;
    },
    resetFlowStages(state: any): void {
      state.flowList = {
        107: [
        { number: 1, name: 'LandingLoanPage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'AppsPage', isAvailable: false },
        { number: 6, name: 'BureausPage', isAvailable: false },
        { number: 7, name: 'LendioPage', isAvailable: false },
        { number: 8, name: 'ThankYouPage', isAvailable: false },
        { number: 9, name: 'AppStatusesPage', isAvailable: false },
      ],
      108: [
        { number: 1, name: 'AppStatusesPage', isAvailable: true },
        { number: 2, name: 'LendioPage', isAvailable: false },
        { number: 3, name: 'ThankYouPage', isAvailable: false },
        { number: 4, name: 'AppStatusesPage', isAvailable: false },
      ],
      206: [
        { number: 1, name: 'LandingCreditScorePage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'AppsPage', isAvailable: false },
        { number: 6, name: 'CreditScorePage', isAvailable: false },
      ],
      207: [
        { number: 1, name: 'CreditScorePage', isAvailable: false },
      ],
      302: [
        { number: 1, name: 'Landing360Page', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'AppsPage', isAvailable: false },
        { number: 6, name: 'BureausPage', isAvailable: false },
      ],
      508: [
        { number: 1, name: 'LandingValuationPage', isAvailable: true },
        { number: 2, name: 'BusinessValuePage', isAvailable: true },
        { number: 3, name: 'RegistrationPage', isAvailable: false },
        { number: 4, name: 'LoginPage', isAvailable: false },
        { number: 5, name: 'NamePage', isAvailable: false },
        { number: 6, name: 'BusinessValueResultsPage', isAvailable: false },
      ],
      600: [
        { number: 1, name: 'LandingCashManagementPage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'CashManagementPage', isAvailable: false },
      ],
      700: [
        { number: 1, name: 'LandingCarbonPage', isAvailable: true },
        { number: 2, name: 'RegistrationPage', isAvailable: false },
        { number: 3, name: 'LoginPage', isAvailable: false },
        { number: 4, name: 'NamePage', isAvailable: false },
        { number: 5, name: 'CarbonFootprintPage', isAvailable: false },
      ],
    };
    state.currentStage = 0;
    state.currentFlow = '';
    state.lastRouteName = '';
    state.completedFlowsArr = [];
    },
    resetFlowVersion(state: any): void {
      state.currentFlow = '';
      state.currentStage = 0;
    },
  },
};
