import {
  exitFromFlow,
  getCreditScorePath,
  getDashboardPath,
} from '@/api/flowSession';
import { actualUrl } from '@/api/instance';
import { sendUtm } from '@/api/utmApi';
import store from '@/store';
import VueRouter from 'vue-router';

const defaultFlowVersion = () => (store.state as any).tracking.flowNumberVersion;
const defaultUtm = () => (store.state as any).tracking.utm;

export const enterToTheFlow = async (
  flowVersion: string = defaultFlowVersion(),
  utm: string = defaultUtm()
): Promise<void> => {
  await sendUtm(utm);
  // await sendUtmToHubspot(utm);
  store.commit('tracking/setFlowVersion', flowVersion);
};


export const finishFlowAndRedirectUserToPortalPage = async (router: VueRouter): Promise<void> => {
  exitFromFlow();
  router.push({ name: 'PortalPage' });
};

export const trackFlow = async (targetPageName: any): Promise<void> => {
  store.commit('flowStages/setCurrentFlowAndStage', { pageName: targetPageName });
};

export const getNextPage = (): string => {
  const isRegistrationCompleted = store.getters['user/isRegistrationCompleted'];
  const isAuthenticated = store.getters['user/isAuthenticated'];
  let nextFlowPageName = store.getters['flowStages/getNextStage'];

  if (nextFlowPageName === 'LoginPage' && isAuthenticated) {
    store.commit('flowStages/setCurrentFlowPlusOne');
    nextFlowPageName = store.getters['flowStages/getNextStage'];
  }

  if (nextFlowPageName === 'NamePage' && isRegistrationCompleted) {
    store.commit('flowStages/setCurrentFlowPlusOne');
    nextFlowPageName = store.getters['flowStages/getNextStage'];
  } else if (nextFlowPageName === 'PortalPage' && !isRegistrationCompleted) {
    nextFlowPageName = 'NamePage';
  }

  return nextFlowPageName;
};

export const getLastPageNew = (): string => {
  const lastPageName = store.getters['flowStages/getLastRouteName'];
  return lastPageName;
};

export const getCurrentFlow = (): void => {
  const currentFlowName = store.getters['flowStages/getCurrentFlow'];
  return currentFlowName;
};

export const getFlowByName = (): Promise<void> => {
  return (store.state as any).flowStages.flowsList.flowVersion;
}

export const goToDashBoard = async (pathName: string): Promise<void> => {
  const { data } = pathName === 'dashboard' ? await getDashboardPath() : await getCreditScorePath();
  const link = `${actualUrl()}admin/${data}`;
  window.location.href = link;
};
