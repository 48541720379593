
import router from '@/router/index';
import { instanceApi } from './instance';
import { logOut } from './authModule';

export default instanceApi.interceptors.response.use((response) => {
  return response;
}, error => {
  const { data } = error.response;

  if (error.response.status === 401) {
    if (data.Type !== "LoginError") {
          logOut();
          router.push({name: 'LoginPage'})
      }
      return Promise.reject(error);
  }

  return error
  
});
