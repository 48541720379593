
import '@/api/interceptors';
// import { loadHubSpotChat } from '@/hubSpotChat';
import { loadScripts } from '@/utils/analyticScriptsUtils';
import { restartTracking } from '@/utils/tracking';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

const exclusionList = ['utils', 'dynamicUrl', 'calculateWindowWidth'];

@Component({
  computed: {
    ...mapState('analytics', ['isDOMReady']),
    ...mapGetters('user', ['isAuthenticated']),
  },
})
export default class App extends Vue {
  public isDOMReady!: number;
  public unSubscribe!: () => void;
  public isAuthenticated!: boolean;

  timerCheckScriptsAreLoaded = 0;

  waitingLibsLoad(): void {
    const { dataLayer } = window as any;
    let { timerCheckScriptsAreLoaded } = this;

    if (dataLayer) {
      Vue.prototype.$dataLayer = dataLayer;
      clearTimeout(timerCheckScriptsAreLoaded);
      this.$dataLayer.push({ event: this.$route.name });
    } else if (!timerCheckScriptsAreLoaded) {
      timerCheckScriptsAreLoaded = setTimeout(this.waitingLibsLoad, 50);
      this.timerCheckScriptsAreLoaded = timerCheckScriptsAreLoaded;
    }
  }
  handleResize(): void {
    this.$store.commit('calculateWindowWidth/resizeWindow', document.documentElement.clientWidth);
  }
  renderReady(): void {
    window.document.dispatchEvent(new Event('renderer-ready'));
  }
  doTracking(): void {
    if (this.isAuthenticated) {
      restartTracking();
    }
  }
  
  // initHubspotChat(): void {
  //   if (this.isAuthenticated) {
  //     loadHubSpotChat();
  //   }
  // }

  synchronizeStateAndStore(): void {
    this.unSubscribe = this.$store.subscribe((mutation, state: any) => {
      if (!exclusionList.includes(mutation.type)) {
        sessionStorage.setItem('store', JSON.stringify({ ...state }));
      }
    });
  }

  created(): void {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize);
    this.$store.commit('analytics/setDOMIsReady', false);
    window.process = {
      env: {
        NODE_ENV: 'production',
      },
    };
    window.hsConversationsSettings = {
        loadImmediately: false
    };
  }
  mounted(): void {
    this.synchronizeStateAndStore();
    this.renderReady();
    // this.initHubspotChat();
    this.$nextTick(() => {
      this.$store.commit('analytics/setDOMIsReady', true);
    });
  }
  destroyed(): void {
    if (this.unSubscribe) {
      this.unSubscribe();
    }
    window.removeEventListener('resize', this.handleResize);
  }

  @Watch('$route')
  onRouteChange(): void {
    this.doTracking();
    if (this.$dataLayer) {
      this.$dataLayer.push({ event: this.$route.path });
    }
  }
  @Watch('isDOMReady')
  checkDomStateAndLoadScripts(): void {
    if (this.isDOMReady) {
      loadScripts();
      this.waitingLibsLoad();
    }
  }
}
