export default {
  namespaced: true,
  state: {
    isDOMReady: false,
  },
  mutations: {
    setDOMIsReady(state: any, status: boolean): void {
      state.isDOMReady = status;
    },
  },
};
