<template functional>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2349_926)">
      <path
        d="M12 5.33335H11.3333V4.00002C11.3333 2.16002 9.83996 0.666687 7.99996 0.666687C6.15996 0.666687 4.66663 2.16002 4.66663 4.00002V5.33335H3.99996C3.26663 5.33335 2.66663 5.93335 2.66663 6.66669V13.3334C2.66663 14.0667 3.26663 14.6667 3.99996 14.6667H12C12.7333 14.6667 13.3333 14.0667 13.3333 13.3334V6.66669C13.3333 5.93335 12.7333 5.33335 12 5.33335ZM7.99996 11.3334C7.26663 11.3334 6.66663 10.7334 6.66663 10C6.66663 9.26669 7.26663 8.66669 7.99996 8.66669C8.73329 8.66669 9.33329 9.26669 9.33329 10C9.33329 10.7334 8.73329 11.3334 7.99996 11.3334ZM10.0666 5.33335H5.93329V4.00002C5.93329 2.86002 6.85996 1.93335 7.99996 1.93335C9.13996 1.93335 10.0666 2.86002 10.0666 4.00002V5.33335Z"
        :fill="props.fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_2349_926">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';
 
const IconLock = defineComponent({
  props: {
    fill: {
      type: String,
      default: '#0D0D0D'
    }
  }
})

export default IconLock
</script>

