
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseInputLanding extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: '' }) styleInput!: string;
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: false }) error!: boolean;
  @Prop({ default: null }) maxLength!: number;

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (this.maxLength) {
      if (target.value.length > this.maxLength) {
        target.value = target.value.slice(0, this.maxLength);
      }
    }
    this.$emit('input', target.value);
  }
}
