import Vue from 'vue';
import Vuex from 'vuex';

//  modules
import analytics from './analytics';
import bureaus from './bureaus';
import calculateWindowWidth from './calculateWindowWidth';
import lendio from './lendio';
import service from './service';
import steps from './steps';
import tracking from './tracking';
import user from './user';
import flowStages from './flowStages';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {
    initialiseStore(state) {
      const savedState = sessionStorage.getItem('store');
      if (savedState) {
        this.replaceState({ ...state, ...JSON.parse(savedState) });
      }
    },
  },
  modules: {
    analytics,
    bureaus,
    calculateWindowWidth,
    flowStages,
    lendio,
    service,
    steps,
    tracking,
    user,
  },
});

export default store;
