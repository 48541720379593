import store from '@/store';
import { AxiosResponse } from 'axios';
import { endTracking } from '@/utils/tracking';
// import { removeHubSpotChat } from '@/hubSpotChat';
import { instanceApi } from './instance';

export const logIn = async (payload: any): Promise<void> => {
  const response = await instanceApi.post('api/auth/code-login', payload);
  instanceApi.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
  localStorage.setItem('token-creditpush', response.data.accessToken);
};

export const logInPassword = async (payload: any): Promise<void> => {
  const response = await instanceApi.post('api/auth/login', payload);
  instanceApi.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
  localStorage.setItem('token-creditpush', response.data.accessToken);
};

export const authUser = async (email: any): Promise<string> => {
  const encodedEmail = encodeURIComponent(email);
  const response = await instanceApi.get(`api/auth/status?email=${encodedEmail}`);
  return response.data.status;
};

export const registration = (payload: any): Promise<AxiosResponse> => instanceApi.post('api/auth/register', payload);

const logOut = async (): Promise<void> => {
  delete instanceApi.defaults.headers.common.Authorization;
  localStorage.clear();
  sessionStorage.removeItem('store');
  store.commit('user/resetUserData');
  store.commit('tracking/resetFlowVersion');
  store.commit('lendio/resetLendioData');
  store.commit('service/resetServices');
  store.commit('steps/resetVisitedPagesArray');

  endTracking();
  // removeHubSpotChat();
};

export const updatePassword = async (password: string): Promise<AxiosResponse> => {
  return instanceApi.post('api/auth/update-password', { password });
};

export { logOut }