export default {
  namespaced: true,
  state: {
    bureausSelected: [],
    agreeWithTermsBureaus: false,
  },
  mutations: {
    setBureaus(state: any, selected: any): void {
      state.bureausSelected = selected;
    },
    addBureaus(state: any, bureauId: any): void {
      state.bureausSelected.push(bureauId);
    },
    selectAllBureaus(state: any, allBureauId: any): void {
      state.bureausSelected = allBureauId;
    },
    deleteBureaus(state: any, bureauId: any): void {
      state.bureausSelected = state.bureausSelected.filter((id: any) => id !== bureauId);
    },
    deleteAllBureaus(state: any): void {
      state.bureausSelected = [];
    },
    setAgreeWithTermsBureaus(state: any, agreeWithTermsBureaus: boolean): void {
      state.agreeWithTermsBureaus = agreeWithTermsBureaus;
    },
  },
};
