import { getCompaniesList } from '@/api/common';

export default {
  namespaced: true,
  state: {
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    zipCode: '',
    stateName: '',
    optionsCompaniesList: [],
    selectedCompany: '',
    phone: '',
    externalAccountId: '',
    externalUserId: '',
    annualRevenueLevel: '',
    peerCompany: '',
    industryForBusinessValuation: '',
    isPortalPageVisited: false,
    isRegistrationCompleted: false,
    optionsIndustries: [],
    optionsLocations: [],
  },
  getters: {
    phone(state: any): boolean {
      return state.phone.replace('+1', '');
    },
    isAuthenticated(state: any): boolean {
      return Boolean(state.externalAccountId);
    },
    isRegistrationCompleted(state: any): boolean {
      return state.isRegistrationCompleted;
    },
  },
  mutations: {
    rewriteUserDataFromResponse(state: any, responseData: any): void {
      const { zip, stateCompany } = responseData.company.address;
      const { name, externalAccountId } = responseData.company;
      const {
        firstName, lastName, phone, externalUserId, email, isPortalPageVisited, isRegistrationCompleted,
      } = responseData.user;

      state.companyName = name;
      state.zipCode = zip;
      state.stateName = stateCompany;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phone = phone;
      state.externalUserId = externalUserId;
      state.externalAccountId = externalAccountId;
      state.email = email;
      state.isPortalPageVisited = isPortalPageVisited;
      state.isRegistrationCompleted = isRegistrationCompleted;

      if (responseData.company.companyDetails.industry) {
        state.industryForBusinessValuation = responseData.company.companyDetails.industry;
      }
    },
    resetUserData(state: any): void {
      state.firstName = '';
      state.lastName = '';
      state.companyName = '';
      state.phone = '';
      state.selectedCompany = '';
      state.externalAccountId = '';
      state.externalUserId = '';
      state.industryForBusinessValuation = '';
      state.optionsIndustries = [];
      state.optionsLocations = [];
      state.isPortalPageVisited = false;
    },
    setEmail(state: any, email: string): void {
      state.email = email.toLowerCase();
    },
    setFirstName(state: any, firstName: any): void {
      state.firstName = firstName.replace(/\w+/g, (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase());
    },
    setLastName(state: any, lastName: string): void {
      state.lastName = lastName.replace(/\w+/g, (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase());
    },
    setCompanyName(state: any, companyName: any): void {
      state.companyName = companyName;
    },
    setAddress(state: any, address: any): void {
      state.address = address;
    },
    setZipCode(state: any, zipCode: any): void {
      state.zipCode = zipCode;
    },
    setStateName(state: any, stateName: any): void {
      state.stateName = stateName;
    },
    setSelectedCompanie(state: any, selectedCompanie: any): void {
      state.selectedCompanie = selectedCompanie;
    },
    setPhone(state: any, phone: string): void {
      state.phone = phone;
    },
    setSelectedCompany(state: any, selectedCompany: any): void {
      state.selectedCompany = selectedCompany;
    },
    setAnnualRevenueLevel(state: any, annualRevenueLevel: string): void {
      state.annualRevenueLevel = annualRevenueLevel;
    },
    setNewCompaniesList(state: any, optionsCompaniesList: any): void {
      state.optionsCompaniesList = optionsCompaniesList;
    },
    setPeerCompany(state: any, peerCompany: any): void {
      state.peerCompany = peerCompany;
    },
    setOptionsIndustries(state: any, optionsArray: string): void {
      state.optionsIndustries = optionsArray;
    },
    setOptionsLocations(state: any, optionsArray: string): void {
      state.optionsLocations = optionsArray;
    },
    setIndustryForBusinessValuation(state: any, industry: string): void {
      state.industryForBusinessValuation = industry;
    },
    setUserWasOnPortalPage(state: any): void {
      state.isPortalPageVisited = true;
    },
    setIsRegistrationCompleted(state: any): void {
      state.isRegistrationCompleted = true;
    },
  },
  actions: {
    async getNewCompaniesList(context: any): Promise<void> {
      const response = (await getCompaniesList()) as any;
      const mapedList = response.data.map(({ name: value, ...rest }: any) => ({
        value,
        ...rest,
      }));
      context.commit('setNewCompaniesList', mapedList);
    },
  },
};
