import axios from 'axios';

export const actualUrl = (): any => {
  const dynamicUrl = window.location.host;
  switch (dynamicUrl) {
    case 'app.creditpush.com':
    case 'creditpush.com':
      return 'https://app.creditpush.com/';
    case 'test.creditpush.com':
      return 'https://test.creditpush.com/';
    default:
      return 'https://dev.creditpush.com/';
  }
};

const defaultHeaders : any = {
  accept: 'application/json',
};

export const instanceApi = axios.create({
  baseURL: `${actualUrl()}service/`,
  headers: defaultHeaders,
});

export const instanceAdminApi = axios.create({
  baseURL: `${actualUrl()}admin/`,
  headers: defaultHeaders,
});
