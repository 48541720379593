import store from '@/store';
import { Route } from 'vue-router';

const getTrackingParameter = (queryParam: string) => {
  const paramsArray = window.location.search.replace('?', '').split('&');
  return paramsArray.filter((param: string) => param.includes(queryParam)).join('&');
}

export const defineUTM = (): void => {
  const utms = getTrackingParameter('utm')
  if (utms) store.commit('tracking/setUtm', utms);
};

export const defineFlowVersion = (to: Route): void => {
  const { flowVersion } = to.query

  if (flowVersion) {
    store.commit('tracking/setFlowVersion', flowVersion);
    store.commit('steps/refreshPageSettings', {
      RegistrationPage: flowVersion,
      ForgotPasswordPage: flowVersion,
      CreateAccountPage: flowVersion,
      BusinessValuePage: flowVersion
    });
  }
};
