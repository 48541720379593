export default function (Vue: any): void {
  Vue.directive('lazy', {
    bind: (el: any, { value }: {value: string}) => {
      if (value) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry && entry.isIntersecting) {
              if (el.tagName === 'IMG') {
                /* eslint no-param-reassign: "error" */
                el.src = value;
              } else {
                el.style.backgroundImage = `url("${value}")`;
              }

              observer.disconnect();
            }
          });
        });

        observer.observe(el as any);
        el.$lazy = observer;
      }
    },
    unbind(el: any) {
      if (el.$lazy) {
        el.$lazy.unobserve(el);
      }
    },
  });
}
