import { AxiosResponse } from 'axios';
import { instanceApi } from './instance';

// Get existing flow
export const activeFlow = (): Promise<AxiosResponse> => instanceApi.get('api/flow-session');

// Exit from flow
export const exitFromFlow = (): Promise<AxiosResponse> => instanceApi.post('api/flow-session/abandon');

// Start flow
export const startFlow = (flowVersion: any): Promise<AxiosResponse> => instanceApi.post(`api/flow-session/start?flowVersion=${flowVersion}`);

// set cuurent stage
export const setCurrentStage = (stage: any): Promise<AxiosResponse> => instanceApi.post(`api/flow-session/current-stage?currentStage=${stage}`);

// Finish flow
export const finishFlow = (): Promise<AxiosResponse> => instanceApi.post('api/flow-session/finish');

// Finish flow
export const getCompletedFlows = (): Promise<AxiosResponse> => instanceApi.get('api/flow-session/complete');

// Get dashboard link
export const getDashboardPath = (): Promise<AxiosResponse> => instanceApi.get('/api/external-auth/sso-login-v3-link');

// Get dashboard credit score link
export const getCreditScorePath = (): Promise<AxiosResponse> => instanceApi.get('api/external-auth/sso-credit-score-link');
