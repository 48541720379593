import { render, staticRenderFns } from "./IconBreadcrumb.vue?vue&type=template&id=f2c03770&scoped=true&functional=true"
import script from "./IconBreadcrumb.vue?vue&type=script&lang=js"
export * from "./IconBreadcrumb.vue?vue&type=script&lang=js"
import style0 from "./IconBreadcrumb.vue?vue&type=style&index=0&id=f2c03770&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "f2c03770",
  null
  
)

export default component.exports