import Vue from 'vue';
import Notifications from 'vue-notification';
import App from './App.vue';
import router from './router';
import store from './store';
import Lazy from './directives/lazy';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'us-accounts',
  'us-payment-calendar',
  'us-credit-score',
  'us-evaluation',
  'us-kpi',
  'us-cash-flow',
  'us-carbon-footprint',
];

Vue.use(Notifications);
Vue.use(Lazy);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
