import { getFilteredAppsList, getIntegrationsApps } from '@/api/appsModule';
import { AxiosResponse } from 'axios';

export default {
  namespaced: true,
  state: {
    appsArray: [],
  },
  mutations: {
    resetServices(state: any): void {
      state.appsArray = [];
    },
    setAppsArray(state: any, apps: any): void {
      state.appsArray = apps;
    },
  },
  actions: {
    async loadAppsAction(context: any): Promise<AxiosResponse> {
      const businessValuePoolServiceInstanceId = '08dac64b-7402-4ed9-8693-0773836cb2e1'; // three apps
      const allPoolServiceInstanceId = '00d5c166-9911-45fc-8db4-79d94987ee99'; // all apps

      const { flowNumberVersion } = context.rootState.tracking;
      const validFlowNumbers = ['501', '503', '504', '505', '507'];

      const poolServiceInstanceId = validFlowNumbers.includes(flowNumberVersion) && context.rootGetters['user/isAuthenticated']
        ? businessValuePoolServiceInstanceId
        : allPoolServiceInstanceId;

      const listApps = await getFilteredAppsList({
        poolServiceInstanceId,
        categoryIds: [],
        isSharedOnly: false,
      });
      context.commit('setAppsArray', listApps.data);
      return listApps;
    },
    async loadAppsForIntegrations(context: any): Promise<AxiosResponse> {
      const allPoolServiceInstanceId = '00d5c166-9911-45fc-8db4-79d94987ee99'; // all apps

      const listApps = await getIntegrationsApps(allPoolServiceInstanceId);

      context.commit('setAppsArray', listApps.data);
      return listApps;
    },
  },
};
