<template functional>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00106 0C5.76672 0 3.94024 1.86847 3.94024 4.1542C3.94024 6.43993 5.76672 8.3084 8.00106 8.3084C10.2354 8.3084 12.0619 6.43993 12.0619 4.1542C12.0619 1.86847 10.2354 0 8.00106 0Z"
      :fill="props.fill"
    />
    <path
      d="M14.9701 11.6281C14.8637 11.356 14.7218 11.102 14.5622 10.8662C13.7465 9.63264 12.4875 8.81632 11.0689 8.61677C10.8916 8.59865 10.6965 8.6349 10.5546 8.74375C9.80985 9.30611 8.92323 9.59635 8.0011 9.59635C7.07897 9.59635 6.19234 9.30611 5.44756 8.74375C5.30569 8.6349 5.11062 8.58049 4.93331 8.61677C3.51468 8.81632 2.23793 9.63264 1.43996 10.8662C1.28037 11.102 1.13849 11.3742 1.03212 11.6281C0.978932 11.737 0.996649 11.864 1.04984 11.9728C1.19171 12.2268 1.36902 12.4808 1.52862 12.6984C1.77687 13.0431 2.04287 13.3515 2.34434 13.6417C2.59259 13.8957 2.87631 14.1315 3.16006 14.3674C4.56094 15.4377 6.24557 16 7.98338 16C9.7212 16 11.4058 15.4376 12.8067 14.3674C13.0904 14.1497 13.3741 13.8957 13.6224 13.6417C13.9061 13.3515 14.1899 13.0431 14.4381 12.6984C14.6155 12.4626 14.7751 12.2268 14.9169 11.9728C15.0055 11.864 15.0233 11.7369 14.9701 11.6281Z"
      :fill="props.fill"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    fill: {
      type: String,
      default: '#0D0D0D'
    }
  }
})
</script>
