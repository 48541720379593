<template functional>
  <svg
    :class="['circle-preloader', { 'black-preloader': props.isBlack }, customClass]"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6"
      width="4"
      height="4"
      rx="2"
      fill="white"
    />
    <rect
      opacity="0.5"
      x="6"
      y="12"
      width="4"
      height="4"
      rx="2"
      fill="white"
    />
    <rect
      opacity="0.8"
      x="0.928925"
      y="3.75735"
      width="4"
      height="4"
      rx="2"
      transform="rotate(-45 0.928925 3.75735)"
      fill="white"
    />
    <rect
      opacity="0.4"
      x="9.41422"
      y="12.2426"
      width="4"
      height="4"
      rx="2"
      transform="rotate(-45 9.41422 12.2426)"
      fill="white"
    />
    <rect
      opacity="0.7"
      y="10"
      width="4"
      height="4"
      rx="2"
      transform="rotate(-90 0 10)"
      fill="white"
    />
    <rect
      opacity="0.3"
      x="12"
      y="10"
      width="4"
      height="4"
      rx="2"
      transform="rotate(-90 12 10)"
      fill="white"
    />
    <rect
      opacity="0.6"
      x="3.75735"
      y="15.0711"
      width="4"
      height="4"
      rx="2"
      transform="rotate(-135 3.75735 15.0711)"
      fill="white"
    />
    <rect
      opacity="0.2"
      x="12.2426"
      y="6.58578"
      width="4"
      height="4"
      rx="2"
      transform="rotate(-135 12.2426 6.58578)"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  Props: {
    customClass: {
      type: String,
      default: ''
    },
    isBlack: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style lang="scss" scoped>
.circle-preloader {
  height: 24px;
  width: 24px;
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(360deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(360deg);
    }
  }
}

.black-preloader {
  rect {
    fill: $gray;
  }
}
</style>
