
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import TheCreditpushMainHeader from '@/components/creditpushMain/TheCreditpushMainHeader.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { TheCreditpushMainHeader, BaseButton },
})
export default class ErrorPage extends Vue {
  goToHomePage(): void {
    this.$router.push({ name: 'HomePage' });
  }

  mounted(): void {
    document.title = 'CreditPush. Sorry, we could not find the page';
  }
}
