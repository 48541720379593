
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconArrow from '@/components/icons/IconArrow.vue';
import BaseCirclePreloader from '@/components/baseComponents/BaseCirclePreloader.vue';

@Component({
  components: {
    BaseCirclePreloader,
    IconArrow,
  },
})
export default class BaseButton extends Vue {
  @Prop({ default: '' }) type!: string;
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: 'blue' }) visualStyle!: string;
  @Prop({ default: 'medium' }) size!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isButtonPreloaderShow!: boolean;
  @Prop({ default: false }) isBackArrow!: boolean;
  @Prop({ default: false }) isNextArrow!: boolean;
  @Prop({ default: false }) isBackButtonType!: boolean;

  get isDisabled(): boolean {
    return this.disabled || this.isButtonPreloaderShow;
  }
  get textStyle(): string {
    if (this.size === 'large') return 'font-medium body1';
    if (this.size === 'medium') return 'font-semibold body2';
    if (this.size === 'small') return 'font-semibold caption2';
    return '';
  }
  async backButtonAction(): Promise<void> {
    if (!this.isBackButtonType) return;

    if (this.$route.name === 'GetLoanPage') {
      this.$router.push({ name: 'ThankYouPage' });
    } else {
      const lastRouteName = this.$store.getters['flowStages/getLastRouteName'];
      this.$router.push({ name: lastRouteName });
    }
  }
}
