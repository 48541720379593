export default {
  namespaced: true,
  state: {
    personalCreditScore: '',
    amountSeeking: '',
    timeInBusiness: '',
    averageMonthlySales: '',
    agreeWithTerms: false,
    urlLendio: '',
  },
  mutations: {
    rewriteLendioDataFromResponse(state: any, responseData: any): void {
      const { amountSeeking, timeInBusiness, averageMonthlySales } = responseData.company.companyDetails;
      const { personalCreditScore } = responseData.user;

      if (personalCreditScore) {
        state.personalCreditScore = personalCreditScore;
        state.amountSeeking = amountSeeking;
        state.timeInBusiness = timeInBusiness;
        state.averageMonthlySales = averageMonthlySales;
      }
    },
    resetLendioData(state: any): void {
      state.personalCreditScore = 0;
      state.amountSeeking = 0;
      state.timeInBusiness = 0;
      state.averageMonthlySales = 0;
      state.agreeWithTerms = false;
    },
    setPersonalCreditScore(state: any, personalCreditScore: string): void {
      state.personalCreditScore = personalCreditScore;
    },
    setAmountSeeking(state: any, amountSeeking: string): void {
      state.amountSeeking = amountSeeking;
    },
    setTimeInBusiness(state: any, timeInBusiness: string): void {
      state.timeInBusiness = timeInBusiness;
    },
    setAverageMonthlySales(state: any, averageMonthlySales: string): void {
      state.averageMonthlySales = averageMonthlySales;
    },
    setAgreeWithTerms(state: any, agreeWithTerms: boolean): void {
      state.agreeWithTerms = agreeWithTerms;
    },
    setLendioUrl(state: any, url: string): void {
      state.urlLendio = url;
    },
  },
};
