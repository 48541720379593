import { sendTrackingData } from '@/api/trackingApi';
import store from '@/store';

const trackingObj: any = {
  isTracking: true,
  interval: 15000,
  intervalFunc: null,
};

const track = (trackingPayload: any): void => {
  if (trackingObj.isTracking) {
    sendTrackingData(trackingPayload);
    trackingObj.isTracking = false;
  }
};

const clearExistingInterval = () => {
  if (trackingObj.intervalFunc) {
    clearInterval(trackingObj.intervalFunc);
  }
};

const turnOnTrackingRequest = () => {
  if (!trackingObj.isTracking) {
    trackingObj.isTracking = true;
  }
};

const mouseMovement = () => {
  turnOnTrackingRequest();
};

export function restartTracking(): void {
  clearExistingInterval();
  document.addEventListener('mousemove', mouseMovement);

  const flowName = () => {
    if (window.location.href.includes('portal')) return '';
    return store.getters['tracking/getFlowName'] ? `${store.getters['tracking/getFlowName']}/` : '';
  };
  const modifiedLocation = window.location.href.replace('.com/', `.com/${flowName()}`);

  const trackingPayload: any = {
    accountId: (store.state as any).user.externalAccountId,
    userId: (store.state as any).user.externalUserId,
    type: 'heartbeat',
    href: modifiedLocation,
  };

  track(trackingPayload);

  trackingObj.intervalFunc = setInterval((): any => {
    track(trackingPayload);
  }, trackingObj.interval);
}

export function endTracking(): void {
  document.removeEventListener('mousemove', mouseMovement);
  clearExistingInterval();
}
