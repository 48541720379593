import { lfeederString } from '@/lfeederScript';
import { gtmString } from '@/gtmScript';

export const loadScripts = (): void => {
  const head = document.getElementsByTagName('head')[0];
  const pingdomScriptUrl = '//rum-static.pingdom.net/pa-62fb6a92405b1d00110011b5.js';
  const pingdomScript = document.createElement('script');
  pingdomScript.src = pingdomScriptUrl;
  pingdomScript.async = true;
  head.appendChild(pingdomScript);

  const scriptGTM = document.createElement('script');
  scriptGTM.type = 'text/javascript';
  scriptGTM.async = true;
  scriptGTM.innerHTML = gtmString;
  document.head.appendChild(scriptGTM);
  
  const scriptLfeeder = document.createElement('script');
  scriptLfeeder.type = 'text/javascript';
  scriptLfeeder.async = true;
  scriptLfeeder.innerHTML = lfeederString;
  document.head.appendChild(scriptLfeeder);
};
