export default {
  namespaced: true,
  state: {
    windowWidth: 0,
  },
  getters: {
    isDesktopSize(state: any): boolean {
      return state.windowWidth > 1279;
    },
    isTabletSize(state: any): boolean {
      return state.windowWidth > 763 && state.windowWidth <= 1279;
    },
    isMobileSize(state: any): boolean {
      return state.windowWidth <= 763;
    },
  },
  mutations: {
    resizeWindow(state: any, payload: any): void {
      state.windowWidth = payload;
    },
  },
};
