export default {
  namespaced: true,
  state: {
    utm: '',
    flowNumberVersion: '',
  },
  getters: {
    getFlowName(state: any): string {
      const flowDictionary: Record<string, string> = {
        107: 'business-loan',
        108: 'business-loan',
        206: 'credit-score',
        207: 'credit-score',
        302: 'business360',
        508: 'business-valuation',
        600: 'cash-management',
      };

      return flowDictionary[state.flowNumberVersion];
    },
  },
  mutations: {
    setUtm(state: any, utm: string): void {
      state.utm = utm;
    },
    setFlowVersion(state: any, flowNumberVersion: string): void {
      if (flowNumberVersion) state.flowNumberVersion = flowNumberVersion;
    },
    resetUtm(state: any): void {
      state.utm = '';
    },
    resetFlowVersion(state: any): void {
      state.flowNumberVersion = '';
    },
  },
};
